import * as React from 'react'

const Footer = (() => {

    return (
        <div className="flex flex-col bg-white justify-center w-full">
            <div className="py-7 mx-auto">
                <span className="font-title text-grey-600">
                    Made with ❤️ by Diky Hadna
                </span>
            </div>
        </div>
    )
})

export default Footer