import * as React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"

import Home from "./pages/Home"
import YoutubeRepeater from "./pages/YoutubeRepeater"
import SpotifyFilter from "./pages/SpotifyFilter"
import InstagramFilter from "./pages/InstagramFilter"

const App = (() => {
  return (
    <Router>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/youtube-repeater" exact>
            <YoutubeRepeater />
          </Route>
          <Route path="/spotify-filter" exact>
            <SpotifyFilter />
          </Route>
          <Route path="/instagram-filter" exact>
            <InstagramFilter />
          </Route>
        </Switch>
    </Router>
  );
})

export default App;
