import * as React from 'react'

const Closing = (() => {

    return (
        <div className="grid justify-items-start md:justify-items-center px-12 py-12 bg-white">
            <h1 className="text-6xl sm:text-8xl xl:text-9xl text-gray-700 mt-7 font-bold md:tracking-tighter text-left md:text-center md:w-3/5">Thank you for using it!</h1>
            <span className="text-4xl text-gray-700 mt-12 font-thin text-left md:text-center md:w-2/4">I hope you had an enjoyable experience. Please consider donating to support the development. One-time donations are always appreciated! 🤝</span>
            <a href="https://www.buymeacoffee.com/dkhd" target="_blank" rel="noreferrer" className="shadow-xl bg-yellow-500 hover:bg-yellow-400 px-8 py-5 rounded-full text-white mt-20 mx-auto">☕ &nbsp; Buy me a coffee</a>
        </div>
    )
})

export default Closing