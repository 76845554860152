import * as React from 'react'
import ProductHeader from "../components/Home/ProductHeader"
import Footer from "../components/Home/Footer"
import ImageFilter from 'react-image-filter'
import html2canvas from 'html2canvas';
// import * as imgSS from 'image-screenshot'

const products = {
    title: 'Spotify Photo Filter',
    image: 'https://images.unsplash.com/photo-1567443024551-f3e3cc2be870?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80',
    description: 'Duotone filter is already exist for some time, but Spotify managed to popularize it even more. You can use this mini-product to edit your photos with Spotify-like filters.'
}

// RGB format
const colorFilter = [
    [
        [0, 0, 126],
        [106, 255, 127]
    ],
    [
        [250, 150, 30],
        [40, 250, 250]
    ],
    [
        [25, 37, 80],
        [240, 14, 46]
    ],
    [
        [227, 65, 136],
        [255, 246, 57],
    ],
    [
        [30, 50, 101],
        [255, 100, 56],
    ],
    [
        [81, 55, 80],
        [148, 224, 212],
    ],
    [
        [28, 25, 82],
        [111, 237, 101],
    ],
    [
        [16, 17, 36],
        [235, 14, 82]
    ],
    [
        [19, 19, 95],
        [251, 244, 100]
    ],
    [
        [204, 55, 54],
        [240, 234, 67]
    ],
    [
        [104, 34, 24],
        [248, 190, 61]
    ],
    [
        [127, 1, 211],
        [1, 219, 254]
    ],
    [
        [1, 171, 109],
        [251, 240, 25]
    ],
    [
        [255, 93, 119],
        [251, 205, 32]
    ],
    [
        [17, 36, 94],
        [220, 67, 121]
    ],
    [
        [41, 9, 0],
        [255, 239, 179]
    ],
    [
        [96, 36, 87],
        [172, 212, 157]
    ],
    [
        [10, 5, 5],
        [240, 14, 46]
    ],
    [
        [80, 98, 214],
        [239, 0, 158]
    ],
    [
        [134, 130, 217],
        [222, 252, 254]
    ],
    [
        [101, 183, 214],
        [253, 217, 226]
    ],
    [
        [36, 26, 95],
        [1, 171, 109]
    ],
    [
        [54, 32, 12],
        [255, 151, 56]
    ],
    [
        [47, 7, 129],
        [223, 178, 51]
    ]
]


const SpotifyFilter = (() => {
    const [image, setImage] = React.useState("https://images.unsplash.com/photo-1458966480358-a0ac42de0a7a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80")
    const [imageSize, setImageSize] = React.useState({
        width: 0,
        height: 0
    })
    const [appliedColor, setAppliedColor] = React.useState(colorFilter[0])

    const ColorButtons = colorFilter.map((val, index) => {
        const color1 = "rgb(" + String(val[0]).replace("[", "").replace("]", "") + ")"
        const color2 = "rgb(" + String(val[1]).replace("[", "").replace("]", "") + ")"
        return (
            <button className="flex flex-row w-3/12 md:w-1/12 h-12 my-2 shadow-xl" onClick={(() => setAppliedColor(colorFilter[index]))} key={index}>
                <div className="w-2/4 h-full" style={{ backgroundColor: color1 }}></div>
                <div className="w-2/4 h-full" style={{ backgroundColor: color2 }}></div>
            </button>
        )
    });

    const invertColor = (() => {
        const invertedColor = [] as any
        for (var i = appliedColor.length - 1; i >= 0; i--) {
            invertedColor.push(appliedColor[i]);
        }
        setAppliedColor(invertedColor)
    })

    const imageSelected = ((event: React.ChangeEvent<HTMLInputElement>) => {
        let file = (event.currentTarget.files)!
        getBase64(file[0])
            .then(result => {
                setImage(result)
                var i = new Image();
                i.onload = function () {
                    setImageSize({
                        width: i.width,
                        height: i.height
                    })
                };
                i.src = result;

            })
            .catch(err => {
                console.log(err)
            })
    })

    const getBase64 = ((file) => {
        return new Promise<string>(resolve => {
            let baseURL: any = ""
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                baseURL = reader.result
                resolve(baseURL)
            }
        })
    })

    const downloadImage = (() => {
        let nodes = document.getElementsByClassName('ImageFilter');
        let htmlElement: any = ""
        for (let i = 0; nodes[i]; i++) {
            htmlElement = (nodes[i] as HTMLElement);
            break
        }
        html2canvas(htmlElement).then(function (canvas) {
            let cv2 = document.createElement('canvas')
            cv2.width = imageSize.width;
            cv2.height = imageSize.height;
            let context = cv2.getContext('2d');
            const offset = 0.01
            context?.drawImage(canvas, 0, 0, cv2.width + (cv2.width * offset), cv2.height + (cv2.height * offset))
            var img = cv2.toDataURL()
            const anchor = document.createElement('a')
            anchor.href = img
            anchor.style.display = 'none'
            anchor.setAttribute('download', "spotify-filter-by-hadna-space.png")
            document.body.appendChild(anchor)
            anchor.click()
            document.body.removeChild(anchor)
        });
    })

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="bg-white w-full h-full flex items-center flex-col">
            <div className="w-full">
                <div className="justify-center items-center">
                    <ProductHeader title={products.title} image={products.image} description={products.description}></ProductHeader>
                </div>
                <div className="flex flex-col items-center mt-20">
                </div>
                <div className=" flex flex-col w-full my-20 items-center">
                    <input className="mb-20 px-8 py-8 border-2 border-blue-300 focus:outline-none focus:ring focus:border-blue-300" type="file" accept="image/png, image/gif, image/jpeg" onChange={imageSelected}></input>
                    <div className="imageFilterHere mx-auto w-10/12 md:w-8/12">
                        <ImageFilter
                            image={image}
                            filter={'duotone'}
                            colorOne={appliedColor[0]}
                            colorTwo={appliedColor[1]}
                        />
                    </div>
                    <div className="flex flex-col mx-auto justify-center gap-8 mt-10">
                        <button className="flex flex-row px-8 py-5 bg-yellow-500 hover:bg-yellow-400 text-gray-100 rounded-full" onClick={invertColor}>
                            <svg className="h-6 w-6" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="12" cy="12" r="2" />  <path d="M2 12l1.5 2a11 11 0 0 0 17 0l1.5 -2" />  <path d="M2 12l1.5 -2a11 11 0 0 1 17 0l1.5 2" /></svg>
                            &nbsp; Invert Color
                        </button>
                    </div>
                    <div className="flex flex-wrap mx-auto w-10/12 md:w-8/12 justify-center gap-8 mt-10">
                        {ColorButtons}
                    </div>
                    <div className="flex flex-col mx-auto justify-center gap-8 mt-10">
                        <button className="flex flex-row px-8 py-5 bg-radical-red-500 hover:bg-radical-red-400 text-gray-100 rounded-full" onClick={downloadImage}>
                            <svg className="h-6 w-6" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />  <polyline points="7 11 12 16 17 11" />  <line x1="12" y1="4" x2="12" y2="16" /></svg>
                            &nbsp; Download Image
                        </button>
                    </div>
                </div>
                <div className="w-full">
                    <Footer></Footer>
                </div>
            </div>
        </div>
    )
})

export default SpotifyFilter