import * as React from 'react'
import Header from "../components/Home/Header"
import MainContent from "../components/Home/MainContent"
import Footer from "../components/Home/Footer"
import Closing from "../components/Home/Closing"

const Home = (() => {

    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
      return (
        <div className="bg-white w-full h-full flex items-center flex-col">
            <div className="w-full">
                <div className="flex h-screen justify-center items-center">
                    <Header></Header>
                </div>
                <div className="w-full mt-48 md:mt-0">
                    <MainContent></MainContent>
                </div>
                <div className="mt-20">
                    <Closing></Closing>
                </div>
                <div className="mt-20">
                    <Footer></Footer>
                </div>
            </div>
        </div>
    )
})

export default Home