import * as React from 'react'
import Logo from './Logo'

const imgSize = 64

const Header = (() => {

    return (
        <div className="grid justify-items-start md:justify-items-center px-12 py-12">
            <div className="flex flex-wrap xl:content-center">
                <Logo width={imgSize} height={imgSize}></Logo>
                <div className="grid justify-items-start ml-3">
                    <span className="text-gray-900 self-center font-semibold tracking-wide">DIKY HADNA</span>
                    <a href="https://github.com/dkhd" target="_blank" className="text-gray-600 self-center font-thin -mt-3 tracking-widest hover:text-viking-500" rel="noreferrer">@dkhd</a>
                </div>
            </div>
            <h1 className="text-6xl sm:text-8xl xl:text-9xl text-gray-700 mt-7 font-bold md:tracking-tighter text-left md:text-center">Mini Products</h1>
            <span className="text-4xl text-gray-700 mt-7 font-thin md:tracking-widest text-left md:text-center">have fun and boost your happiness!</span>
            <span className="max-w-screen-md text-3xl text-gray-700 mt-16 font-thin text-left md:text-center">All these mini-products are free to use, but any support you give enables me to continue to create and maintenance the service I provide. Have a good day!</span>
            <a href="https://www.buymeacoffee.com/dkhd" target="_blank" rel="noreferrer" className="shadow-xl bg-yellow-500 hover:bg-yellow-400 px-8 py-5 rounded-full text-white mt-20 mx-auto">☕ &nbsp; Buy me a coffee</a>
        </div>
    )
})

export default Header