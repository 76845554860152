import * as React from 'react'
import ProductHeader from "../components/Home/ProductHeader"
import Footer from "../components/Home/Footer"
import ImageFilter from 'react-image-filter'
import html2canvas from 'html2canvas';
// import * as imgSS from 'image-screenshot'

const products = {
    title: 'Instagram Photo Filter',
    image: 'https://images.unsplash.com/photo-1567443024551-f3e3cc2be870?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80',
    description: 'In these recent years, who doesn\'t even know about Instagram, right? This photo-sharing platform has about 500M daily active users, and maybe you\'re one of them? Now, you can polish your photo even more with this Instagram-like photo filter.'
}

// RGB format
const colorFilter = [
    [
        {
            text: "#NoFilter",
            colorMatrix: [
                1, 0, 0, 0, 0,
                0, 1, 0, 0, 0,
                0, 0, 1, 0, 0,
                0, 0, 0, 1, 0,
            ],
        }
    ],
    [
        {
            text: "Sepia",
            colorMatrix: [
                0.39, 0.769, 0.189, 0.0,
                0.0, 0.349, 0.686, 0.168,
                0.0, 0.0, 0.272, 0.534, 0.131,
                0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0
            ],
        }
    ],
    [
        {
            text: "Black & White",
            colorMatrix: [
                0.2126, 0.7152, 0.0722, 0.0, 0.0,
                0.2126, 0.7152, 0.0722, 0.0, 0.0,
                0.2126, 0.7152, 0.0722, 0.0, 0.0,
                0.0, 0.0, 0.0, 1.0, 0.0
            ]
        }
    ],
    [
        {
            text: "Vintage",
            colorMatrix: [
                0.9, 0.5, 0.1, 0.0, 0.0,
                0.3, 0.8, 0.1, 0.0, 0.0,
                0.2, 0.3, 0.5, 0.0, 0.0,
                0.0, 0.0, 0.0, 1.0, 0.0
            ]
        }
    ],
    [
        {
            text: "Spring Tree",
            colorMatrix: [
                1.0, 0.0, 0.2, 0.0, 0.0,
                0.0, 1.0, 0.0, 0.0, 0.0,
                0.0, 0.0, 1.0, 0.0, 0.0,
                0.0, 0.0, 0.0, 1.0, 0.0
            ]
        }
    ],
    [
        {
            text: "Cold Winter",
            colorMatrix: [
                0.4, 0.4, -0.3, 0.0, 0.0,
                0.0, 1.0, 0.0, 0.0, 0.0,
                0.0, 0.0, 1.2, 0.0, 0.0,
                -1.2, 0.6, 0.7, 1.0, 0.0
            ]
        }
    ],
    [
        {
            text: "Dawn",
            colorMatrix: [
                0.8, 0.5, 0.0, 0.0, 0.0,
                0.0, 1.1, 0.0, 0.0, 0.0,
                0.0, 0.2, 1.1, 0.0, 0.0,
                0.0, 0.0, 0.0, 1.0, 0.0
            ]
        }
    ],
    [
        {
            text: "Riverside",
            colorMatrix: [
                1.1, 0.0, 0.0, 0.0, 0.0,
                0.2, 1.0, -0.4, 0.0, 0.0,
                -0.1, 0.0, 1.0, 0.0, 0.0,
                0.0, 0.0, 0.0, 1.0, 0.0
            ]
        }
    ],
    [
        {
            text: "Summer Night",
            colorMatrix: [
                1.2, 0.1, 0.5, 0.0, 0.0,
                0.1, 1.0, 0.05, 0.0, 0.0,
                0.0, 0.1, 1.0, 0.0, 0.0,
                0.0, 0.0, 0.0, 1.0, 0.0
            ]
        }
    ],
    [
        {
            text: "Old Times",
            colorMatrix: [
                1, 0, 0, 0, 0,
                -0.4, 1.3, -0.4, 0.2, -0.1,
                0, 0, 1, 0, 0,
                0, 0, 0, 1, 0
            ]
        }
    ],
    [
        {
            text: "Mexico",
            colorMatrix: [
                1.3, -0.3, 1.1, 0, 0,
                0, 1.3, 0.2, 0, 0,
                0, 0, 0.8, 0.2, 0,
                0, 0, 0, 1, 0
            ]
        }
    ],
    [
        {
            text: "Autumn Leaves",
            colorMatrix: [
                0.567, 0.433, 0, 0, 0,
                0.558, 0.442, 0, 0, 0,
                0, 0.242, 0.758, 0, 0,
                0, 0, 0, 1, 0,
            ]
        }
    ],
]


const InstagramFilter = (() => {
    const [image, setImage] = React.useState("https://images.unsplash.com/photo-1458966480358-a0ac42de0a7a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80")
    const [imageSize, setImageSize] = React.useState({
        width: 0,
        height: 0
    })
    const [appliedColor, setAppliedColor] = React.useState(colorFilter[3])

    const ColorButtons = colorFilter.map((val, index) => {
        return (
            <button className="flex flex-row px-4 py-5 bg-blue-100 rounded-xl" onClick={(() => setAppliedColor(colorFilter[index]))} key={index}>
                {val[0].text}
            </button>
        )
    });


    const imageSelected = ((event: React.ChangeEvent<HTMLInputElement>) => {
        let file = (event.currentTarget.files)!
        getBase64(file[0])
            .then(result => {
                setImage(result)
                var i = new Image();
                i.onload = function () {
                    setImageSize({
                        width: i.width,
                        height: i.height
                    })
                };
                i.src = result;

            })
            .catch(err => {
                console.log(err)
            })
    })

    const getBase64 = ((file) => {
        return new Promise<string>(resolve => {
            let baseURL: any = ""
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                baseURL = reader.result
                resolve(baseURL)
            }
        })
    })

    const downloadImage = (() => {
        let nodes = document.getElementsByClassName('instagramPhotoHere');
        let htmlElement: any = ""
        for (let i = 0; nodes[i]; i++) {
            htmlElement = (nodes[i] as HTMLElement);
            break
        }
        html2canvas(htmlElement).then(function (canvas) {
            let cv2 = document.createElement('canvas')
            cv2.width = imageSize.width;
            cv2.height = imageSize.height;
            let context = cv2.getContext('2d');
            const offset = 0.01
            context?.drawImage(canvas, 0, 0, cv2.width + (cv2.width * offset), cv2.height + (cv2.height * offset))
            var img = cv2.toDataURL()
            const anchor = document.createElement('a')
            anchor.href = img
            anchor.style.display = 'none'
            anchor.setAttribute('download', "spotify-filter-by-hadna-space.png")
            document.body.appendChild(anchor)
            anchor.click()
            document.body.removeChild(anchor)
        });
    })

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="bg-white w-full h-full flex items-center flex-col">
            <div className="w-full">
                <div className="justify-center items-center">
                    <ProductHeader title={products.title} image={products.image} description={products.description}></ProductHeader>
                </div>
                <div className="flex flex-col items-center mt-20">
                </div>
                <div className=" flex flex-col w-full my-20 items-center">
                    <input className="mb-20 px-8 py-8 border-2 border-blue-300 focus:outline-none focus:ring focus:border-blue-300" type="file" accept="image/png, image/gif, image/jpeg" onChange={imageSelected}></input>
                    <div className="instagramPhotoHere mx-auto w-10/12 md:w-8/12">
                        <ImageFilter
                            image={image}
                            filter={appliedColor[0].colorMatrix}
                        />
                    </div>
                    <div className="flex flex-wrap mx-auto w-10/12 md:w-8/12 justify-center gap-4 mt-10">
                        {ColorButtons}
                    </div>
                    <div className="flex flex-col mx-auto justify-center gap-8 mt-10">
                        <button className="flex flex-row px-8 py-5 bg-radical-red-500 hover:bg-radical-red-400 text-gray-100 rounded-full" onClick={downloadImage}>
                            <svg className="h-6 w-6" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />  <polyline points="7 11 12 16 17 11" />  <line x1="12" y1="4" x2="12" y2="16" /></svg>
                            &nbsp; Download Image
                        </button>
                    </div>
                </div>
                <div className="w-full">
                    <Footer></Footer>
                </div>
            </div>
        </div>
    )
})

export default InstagramFilter