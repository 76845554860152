import * as React from 'react'
import ReactPlayer from 'react-player/youtube'
import ProductHeader from "../components/Home/ProductHeader"
import Footer from "../components/Home/Footer"

const products = {
    title: 'Youtube Repeater',
    image: 'https://images.unsplash.com/photo-1567443024551-f3e3cc2be870?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80',
    description: 'So, you are lovin\' that single music video on Youtube and want to repeatedly play it over and over?\n\nSay no more! Here\'s the one you are looking for!'
}



const YoutubeRepeater = (() => {
    const [muted, setMuted] = React.useState(true)
    const [videoUrl, setVideoUrl] = React.useState("https://www.youtube.com/watch?v=3tuZ7Y_TpMw")
    const [play, setPlay] = React.useState(false)
    const [tempVideoUrl, setTempVideoUrl] = React.useState("")
    const [isUrlValid, setIsUrlValid] = React.useState(true)

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const getUrl = ((e: React.FormEvent<HTMLInputElement>) => {
        console.log(e.currentTarget.value);
        setTempVideoUrl(e.currentTarget.value)
        setIsUrlValid(true)
    })

    const launchVideo = ((url: string) => {
        setVideoUrl(url)
        setPlay(true)
        setMuted(false)
        setIsUrlValid(true)
    })

    const playVideo = ((e: React.FormEvent<HTMLButtonElement>) => {
        if (tempVideoUrl.includes("https://www.youtube.com/watch?v=")) {
            launchVideo(tempVideoUrl)
        } else if (tempVideoUrl.includes("https://youtu.be/")) {
            let url = "https://www.youtube.com/watch?v=" + (tempVideoUrl.split("/"))[3]
            launchVideo(url)
        } else if (tempVideoUrl.includes("https://m.youtube.com/watch?v=")) {
            let url = tempVideoUrl.replace("m.", "")
            launchVideo(url)
        }
        else {
            setIsUrlValid(false)
        }
    })

    return (
        <div className="bg-white w-full h-full flex items-center flex-col">
            <div className="w-full">
                <div className="justify-center items-center">
                    <ProductHeader title={products.title} image={products.image} description={products.description}></ProductHeader>
                </div>
                <div className="flex flex-col items-center mt-20">
                    <div className="flex flex-col md:flex-row mx-auto border-4 w-10/12 md:w-8/12 border-radical-red-500">
                        <input type="text" onChange={getUrl} className="px-5 py-5 w-full md:w-7/12 flex-grow focus:outline-none focus:border-0" placeholder="Paste Youtube video url here..."></input>
                        <button className="px-5 py-5 w-100 bg-radical-red-500 hover:bg-radical-red-400" onClick={playVideo}>
                            <p className="w-full mx-auto text-white font-title">
                                ▶️ &nbsp; Play Video
                            </p>
                        </button>
                    </div>
                    {
                        (!isUrlValid) ?
                            <p className="text-radical-red-500 mt-2">Please enter a valid Youtube URL</p>
                            :
                            <div>&nbsp;</div>
                    }
                </div>
                <div className=" flex flex-col w-full my-20 items-center">
                    <div className="mx-auto w-10/12 md:w-8/12 h-64 md:h-96">
                        <ReactPlayer
                            playsinline={true}
                            url={videoUrl}
                            playing={play}
                            muted={muted}
                            loop={true}
                            controls={true}
                            width='100%'
                            height='100%'
                        ></ReactPlayer>
                    </div>
                    <div className="flex flex-col mt-10 w-10/12 md:w-8/12">
                        <span className="italic">Note: Due to limitations/restrictions on mobile devices, this YouTube repeater works best on desktop. If you are using mobile device, please keep your browser open and do not lock your screen. Alternatively, you can use <a className="font-semibold text-radical-red-500 hover:text-radical-red-400" href="https://brave.com" target="_blank" rel="noreferrer">Brave browser</a>, which I also use, and go to <strong>Settings → Enable "Background Video Playback"</strong>, then <strong>request Desktop Site</strong>, and proceed to enter the YouTube video URL.</span>
                    </div>
                </div>
                <div className="w-full">
                    <Footer></Footer>
                </div>
            </div>
        </div>
    )
})

export default YoutubeRepeater