import * as React from 'react'
import {
    Link
} from "react-router-dom"

interface ProductCardProps {
    title: string,
    image: string,
    description: string
}

const ProductHeader = (({ title, image, description }: ProductCardProps) => {

    return (
        <div className="grid justify-items-start md:justify-items-center px-12 py-12">
            <div className="flex flex-wrap xl:content-center">
                <Link className="" to="/">
                    <div className="w-full w-100">
                        <p className="text-center font-bold">
                            ← &nbsp; 🏠 Back Home
                        </p>
                    </div>
                </Link>
            </div>
            <h1 className="text-6xl sm:text-8xl xl:text-9xl text-gray-700 mt-7 font-bold md:tracking-tighter text-left md:text-center">{title}</h1>
            <span className="text-4xl text-gray-700 mt-7 font-thin md:tracking-widest text-left md:text-center">a mini-product by Diky Hadna</span>
            <span className="max-w-screen-md text-3xl text-gray-700 mt-16 font-thin text-left md:text-center">{description}</span>
            <a href="https://www.buymeacoffee.com/dkhd" target="_blank" rel="noreferrer" className="shadow-xl bg-yellow-500 hover:bg-yellow-400 px-8 py-5 rounded-full text-white mt-20 mx-auto">☕ &nbsp; Buy me a coffee</a>
        </div>
    )
})

export default ProductHeader