import * as React from 'react'
import {
    Link
} from "react-router-dom"

const products = [
    {
        title: 'Youtube Repeater',
        path: '/youtube-repeater',
        image: 'https://images.unsplash.com/photo-1567443024551-f3e3cc2be870?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80',
        description: 'So, you are lovin\' that single music video on Youtube and want to repeatedly play it over and over?\n\nSay no more! Here\'s the one you are looking for!'
    },
    {
        title: 'Spotify Photo Filter',
        path: '/spotify-filter',
        image: 'https://i.postimg.cc/Vs35L8w8/spotify-filter-by-hadna-space-finish.png',
        description: 'Duotone filter is already exist for some time, but Spotify managed to popularize it even more. You can use this mini-product to edit your photos with Spotify-like filters.'
    },
    {
        title: 'Instagram Photo Filter',
        path: '/instagram-filter',
        image: 'https://images.unsplash.com/photo-1496664444929-8c75efb9546f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80',
        description: 'In these recent years, who doesn\'t even know about Instagram, right? This photo-sharing platform has about 500M daily active users, and maybe you\'re one of them? Now, you can polish your photo even more with this Instagram-like photo filter.'
    }
]

interface ProductCardProps {
    title: string,
    path: string,
    image: string,
    description: string
}

const ProductCard = (({ title, path, image, description }: ProductCardProps) => {
    return (
        <>
            <div className="flex flex-col filter drop-shadow-2xl bg-white w-full lg:w-3/12 mx-3 my-3">
                <div className="flex flex-col flex-grow">
                    <img src={image} alt="Thumbnail"></img>
                    <div className="flex flex-col px-5 py-5">
                        <p className="text-center font-semibold font-title text-3xl">{title}</p>
                        <p className="text-center font-thin whitespace-pre-wrap my-7">{description}</p>
                    </div>
                </div>
                <Link className="" to={path}>
                    <div className="w-full w-100 bg-radical-red-500 hover:bg-radical-red-400 text-white py-3">
                        <p className="text-center">
                            Open Mini Product
                        </p>
                    </div>
                </Link>
            </div>
        </>
    )
})

const MainContent = (() => {

    return (
        <div className="flex flex-wrap justify-items-center w-full bg-gray-100 py-32">
            <div className="px-16 mx-auto">
                <p className="text-5xl text-gray-600 font-title font-sans font-bold text-center tracking-wide">Mini Products</p>
                <p className="text-2xl text-gray-700 font-thin text-center mt-3">Please be considerate while using it</p>
            </div>
            <div className="flex flex-wrap gap-5 w-full px-10 mt-20 justify-center">
                {
                    products.map((item) => {
                        return (
                            <ProductCard title={item.title} path={item.path} image={item.image} description={item.description}></ProductCard>
                        )
                    })
                }
            </div>
        </div >
    )
})

export default MainContent